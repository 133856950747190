<script setup lang="ts">
import type { SidebarStep } from '@/types/registrationData';

defineOptions({
  name: 'PartnerRegistrationView',
});

const { $mopI18n } = useNuxtApp();
const { initRegistration, initSalesAgents, dataRef } = useRegistrationForm();
const loadingRef = ref(true);
const showLoadingRef = ref(true);

onMounted(async () => {
  await Promise.all([initRegistration(), initSalesAgents()]);
  loadingRef.value = false;

  if (!dataRef.value) {
    return;
  }

  setTimeout(() => {
    showLoadingRef.value = false;
  }, LOADING_DELAY);
});

const steps: SidebarStep[] = [
  {
    componentNames: ['FormRegisterSectionSalesAgent'],
    title: $mopI18n.t('form.sidebar.step.sales_agent'),
  },
  {
    componentNames: ['FormRegisterSectionContactPerson'],
    title: $mopI18n.t('form.sidebar.step.contact_person'),
  },
  {
    componentNames: ['FormRegisterSectionCompanyInformation'],
    title: $mopI18n.t('form.sidebar.step.company_information'),
  },
  {
    componentNames: ['FormRegisterSectionInvoiceAddress'],
    title: $mopI18n.t('form.sidebar.step.invoice_address'),
  },
  {
    componentNames: ['FormRegisterSectionStoreAddressList', 'FormRegisterSectionStoreAddressListSummary'],
    title: $mopI18n.t('form.sidebar.step.store_address'),
  },
  {
    componentNames: ['FormRegisterSectionPaymentDetails'],
    title: $mopI18n.t('form.sidebar.step.payment_details'),
  },
  {
    componentNames: ['FormRegisterSectionEdiInformation'],
    title: $mopI18n.t('form.sidebar.step.edi_info'),
  },
  {
    componentNames: ['FormRegisterSectionLoginForB2B'],
    title: $mopI18n.t('form.sidebar.step.login_b2b'),
  },
  {
    componentNames: ['FormRegisterSummary'],
    title: $mopI18n.t('form.sidebar.step.check'),
  },
];
</script>

<template>
  <div v-if="showLoadingRef" class="partner-registration-loading">
    <Ui2Loader size="lg" />
  </div>
  <div
    v-if="!loadingRef"
    :class="[
      'partner-registration',
      {
        'partner-registration--loading': showLoadingRef,
      },
    ]"
  >
    <FormSidebar :steps="steps" />
    <FormRegister />
  </div>
</template>

<style scoped lang="scss">
.partner-registration-loading {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
}

.partner-registration {
  opacity: 1;
  transition: opacity $animation-duration-long $animation-type-linear;
  padding: $space-96 $space-32 $space-64;
  display: flex;
  gap: $space-64;

  @include v2-apply-upto(mobile) {
    flex-direction: column;
    padding: 0 $space-16 $space-64;
  }
}

.partner-registration--loading {
  opacity: 0;
}
</style>
